
import { ComboBox, ComposedModal, ModalBody, ModalFooter, ModalHeader, NumberInput, TextInput } from 'carbon-components-react'
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import UIUtil from '../../../util/UIUtil';
import { Warning16, RotateCounterclockwiseAlt16 } from '@carbon/icons-react'
import Util from '../../../util/Util';
import Button from '../../../components/Button';
import Api from '../../../session/Api';
import { isQasr } from '../../../app/app-util';

export function openCustomPriceDialog(state, cartItem) {
    state.showSetCartItemPriceDialog(cartItem)
    // UIUtil.showOverlay2(onClose => (
    //     <SetCustomPriceDialog open onClose={onClose} />
    // ))
}

export function SetCustomPriceDialog({ open, onClose, state, cartItem }) {
    const [price, setPrice] = useState(0);
    const [higherThanCogs, setHigherThanCogs] = useState(true);
    const [clearanceCode, setClearanceCode] = useState("")
    const [loading, setLoading] = useState(false)

    const onSetBtn = () => {
        setLoading(true);
        state.setCartItemCustomPrice(cartItem.id, Util.isNumberExist(price) ? price : -1, Util.isStringExists(clearanceCode) ? clearanceCode : "null", () => {
            setLoading(false)
        }, () => {
            setLoading(false)
            setHigherThanCogs(true)
        })
    }

    const onClearBtn = () => {
        setLoading(true);
        state.setCartItemCustomPrice(cartItem.id, -1, Util.isStringExists(clearanceCode) ? clearanceCode : "null", () => {
            setLoading(false)
        }, () => {
            setLoading(false)
            setHigherThanCogs(true)
        })
    }

    useEffect(() => {
        if (open) {
            setPrice(cartItem?.customPrice ?? cartItem?.amount?.price ?? 0);
            setHigherThanCogs(false)
            setClearanceCode("")
            setLoading(false)
        }
    }, [open])

    return (
        <ComposedModal key="set-price-dialog" size="md" open={open} onClose={onClose}>
            <ModalHeader label="Cart Item" title="Set Price" />
            <ModalBody style={{ paddingRight: '1rem', }}>
                {higherThanCogs ? <>

                    <div style={{ marginBottom: '1rem', borderRadius: 7, background: '#99000020', color: 'red', display: 'flex', paddingInline: '1rem', paddingBlock: '0.5rem', gap: '0.5rem' }}>
                        <Warning16 />
                        <p style={{ fontSize: 12, opacity: 0.65 }}>
                            {isQasr() ? 'Please input admin clearance code.' : 'Price is lower than COGS! Please input admin clearance code.'}
                        </p>
                    </div>

                    <TextInput
                        data-modal-primary-focus
                        labelText="Clearance Code"
                        type="password"
                        autoComplete="off"
                        helperText="Admin or supervisor clearance code"
                        value={clearanceCode}
                        onChange={e => setClearanceCode(e.target.value)}
                    />
                </> : <>


                    <NumberInput
                        style={{ flex: 1 }}
                        label="Price"
                        invalidText="Invalid number"
                        data-modal-primary-focus
                        size="xl"
                        value={price}
                        onChange={(e, { value }) => setPrice(value)}
                        hideSteppers
                    //hideLabel
                    />


                    {cartItem?.customPrice &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button kind="tertiary" onClick={onClearBtn} renderIcon={RotateCounterclockwiseAlt16} size="sm" style={{ borderRadius: 25, marginTop: '1rem' }}>Set to original</Button>
                        </div>}


                </>}
            </ModalBody>
            <ModalFooter>
                <Button kind="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    loading={loading}
                    // disabled={cartItem ? ((cartItem.displayQuantityValue == quantityValue && cartItem.displayUom == (selectedUOM ? selectedUOM.id : 0)) || isNaN(quantityValue) || quantityValue == '') : false}
                    disabled={higherThanCogs ? (
                        !Util.isStringExists(clearanceCode)
                    ) : (
                        false
                    )}
                    onClick={onSetBtn}>
                    Set
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}